
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import bodymovin, { AnimationItem } from 'lottie-web';

@Options({
    components: {}
})
export default class ExperienceAffinitySuccessModal extends Vue {

    @Prop() userName: "";
    @Prop() experienceCallback: any;
    @Prop() myApplicationCallback: any;

    created() {}

    mounted() {
        setTimeout(() => {
            var experienceLoop = bodymovin.loadAnimation({
                container: <any>this.$refs.experienceLoop,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: './json/experience_loop.json',
            })

            experienceLoop.play();
        }, 300);
    }

    experienceAction(){
        if(this.experienceCallback){
            this.experienceCallback();
            return;
        }

        this.$opModal.closeLast();
        this.$router.replace('/experience/');
    }

    myApplicationsListAction(){
        if(this.myApplicationCallback){
            this.myApplicationCallback();
            return;
        }

        this.$opModal.closeLast();
        this.$router.replace('/my-application-list/');
    }
}
