
import { Options, Vue } from 'vue-class-component';
import { ExperienceAffinityClient, ExperienceClient, TryAgainClient } from '@/services/Services';
import AffinityAlreadyReachedModal from '../modals/experience/affinityAlreadyReachedModal.vue';
import ExperienceAffinitySuccessModal from '../modals/experience/experienceAffinitySuccessModal.vue';
import ExperienceResume from '../components/experience/experienceResume.vue';
import viewDescriptionModal from '../modals/viewDescriptionModal.vue';
import InfoModal from '@/components/modals/infoModal.vue';
import * as OM from '@/Model';
import affinityNotCompletedModal from '../modals/experience/affinityNotCompletedModal.vue';
import AffinityCompletedAndNotReachedModal from '../modals/experience/affinityCompletedAndNotReachedModal.vue';
import AffinityNotCompletedAndOtherYes from '../modals/experience/affinityNotCompletedAndOtherYes.vue';
import affinityCompletedAndNotReachedModalAndTryAgainUsed from '../modals/experience/affinityCompletedAndNotReachedModalAndTryAgainUsed.vue';
import noPremiumModal from '../modals/premium/noPremiumModal.vue';
import store from '@/store';
import { experienceAffinityDetailCb, newExperienceMatchCallback } from '@/afterPremiumActionCallbacks';
import { UrlServices } from '@/services/UrlServices';
import Hexagon from '@/components/hexagon.vue';

@Options({
    components: {
        ExperienceResume,
        Hexagon
    }
})
export default class ExperienceAffinityDetail extends Vue {

    affinityDetail: OM.ExperienceAffinityDetailVM = new OM.ExperienceAffinityDetailVM();
    tryAgainSummary: OM.TryAgainSummaryVM = new OM.TryAgainSummaryVM();
    experienceIdentifier: string = "";

    created() {

        this.experienceIdentifier = this.$route.params.experienceIdentifier.toString();

        Promise.all([
            ExperienceAffinityClient.getExperienceAffinityDetail(this.experienceIdentifier),
        ])
        .then(xs => {
            this.affinityDetail = xs[0];
            TryAgainClient.getTryAgainSummary(this.affinityDetail.match.matchIdentifier, false)
            .then( x => {
                this.tryAgainSummary = x;
            })
        })
    }

    get affinityRateText(){
        if(this.affinityDetail.match.affinityRateReachedFromOther != null)
            return this.$localizationService.getLocalizedValue("app_AffinityRate{{rate}}Di{{Name}}", "Affinity Rate di {{name}}: {{rate}}%", "", "", { rate: this.affinityDetail.match.minimumAffinityRate, name: this.$filters.cutNameTooLong(this.affinityDetail.experience.creator.text) });   
        else
            return this.$localizationService.getLocalizedValue("app_AffinityRate{{rate}}", "Affinity Rate: {{rate}}%", "", "", { rate: this.affinityDetail.match.minimumAffinityRate });   
    }

    get isCompletedAndMatched(){
        var match = this.affinityDetail.match;

        return match.status == OM.ReplyStatus.Completed && match.isOverMinimumAffinity;
    }

    get isCompletedAndUnmatchedAndTryAgainUsed(){
        var match = this.affinityDetail.match;

        return match.status == OM.ReplyStatus.Completed && !match.isOverMinimumAffinity && match.tryAgainAlreadyUsed;
    }

    get isCompletedAndUnmatched(){
        var match = this.affinityDetail.match;

        return match.status == OM.ReplyStatus.Completed && !match.isOverMinimumAffinity && !match.tryAgainAlreadyUsed;
    }

    get isNotCompleted(){
        var match = this.affinityDetail.match;

        return match.status != OM.ReplyStatus.Completed && !match.affinityRateReachedFromOther;
    }

    get isNotCompletedAndOtherCompleted(){
        var match = this.affinityDetail.match;

        return match.status != OM.ReplyStatus.Completed && match.affinityRateReachedFromOther;
    }

    openDescription() {
        if(!this.affinityDetail.match.biography)
            return;
            
        this.$opModal.show(viewDescriptionModal, {
            ownDescription: false,
            completeName: this.affinityDetail.match.personName,
            birthData: this.affinityDetail.match.birthDate,
            meters: this.affinityDetail.match.distanceMeters,
            sexualOrientation: this.affinityDetail.match.sexualOrientation,
            description: this.affinityDetail.match.biography
        })
    }

    rejectMatch() {
        ExperienceAffinityClient.checkIfUncompletedProfileUserCanCandidateToExperience()
        .then(x => {
            ExperienceClient.refuseExperience(this.affinityDetail.experience.experienceIdentifier)
            .then( x => {
                this.$router.back();
            })
            .catch(err => {
                this.$opModal.show(InfoModal, {
                    img: 'face_error.svg',
                    text: err.Message,
                    confirm: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                    confirmCb: () => {
                        this.$opModal.closeLast();
                    },
                })
            })
        })
        .catch(err => {
            this.$opModal.show(InfoModal, {
                img: 'face_error.svg',
                text: err.Message,
                confirm: err.CallbackParams != null ? err.CallbackParams.CallbackText : this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                confirmCb: () => {
                    if(err.CallbackParams){
                        if(err.CallbackParams.OpenBlank)
                            UrlServices.openBlank(err.CallbackParams.CallbackRoute)
                        else 
                            this.$router.push(err.CallbackParams.CallbackRoute);
                    }

                    this.$opModal.closeLast();
                },
                deny: err.RefuseCallbackParams != null ? err.RefuseCallbackParams.CallbackText : null,
                denyCb: !err.RefuseCallbackParams ? null : () => {
                    if(err.RefuseCallbackParams.OpenBlank)
                        UrlServices.openBlank(err.RefuseCallbackParams.CallbackRoute)
                    else 
                        this.$router.push(err.RefuseCallbackParams.CallbackRoute);
                    
                    this.$opModal.closeLast();
                }
            })
        })
    }

    replyToMatch() {
        ExperienceAffinityClient.checkIfUncompletedProfileUserCanCandidateToExperience()
        .then(x => {
            ExperienceAffinityClient.checkIfCanReply(false)
            .then(x => {
                this.proceedWithExperienceReply();
            })
            .catch(err => {
                this.$opModal.show(InfoModal, {
                    img: 'face_error.svg',
                    text: err.Message,
                    confirm: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                    confirmCb: () => {
                        this.$opModal.closeLast();
                    },
                })
            })
        })
        .catch(err => {
            this.$opModal.show(InfoModal, {
                img: 'face_error.svg',
                text: err.Message,
                confirm: err.CallbackParams != null ? err.CallbackParams.CallbackText : this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                confirmCb: () => {
                    if(err.CallbackParams){
                        if(err.CallbackParams.OpenBlank)
                            UrlServices.openBlank(err.CallbackParams.CallbackRoute)
                        else 
                            this.$router.push(err.CallbackParams.CallbackRoute);
                    }

                    this.$opModal.closeLast();
                },
                deny: err.RefuseCallbackParams != null ? err.RefuseCallbackParams.CallbackText : null,
                denyCb: !err.RefuseCallbackParams ? null : () => {
                    if(err.RefuseCallbackParams.OpenBlank)
                        UrlServices.openBlank(err.RefuseCallbackParams.CallbackRoute)
                    else 
                        this.$router.push(err.RefuseCallbackParams.CallbackRoute);
                    
                    this.$opModal.closeLast();
                }
            })
        })
    }

    proceedWithExperienceReply(){
        if(this.affinityDetail.match.isSecondChance && !this.tryAgainSummary.isUserPremium)
            this.secondChanceAndNotPremium();
        else if(this.isCompletedAndMatched)
            this.affinityAlreadyReached();
        else if(this.isCompletedAndUnmatched)
            this.affinityAlreadyCompletedAndUnmatched();
        else if(this.isCompletedAndUnmatchedAndTryAgainUsed)
            this.affinityAlreadyCompletedAndUnmatchedAndTryAgainUsed();
        else if(this.isNotCompleted)
            this.affinityNotCompleted();
        else if(this.isNotCompletedAndOtherCompleted)
            this.affinityNotCompletedAndOtherYes();
        // ExperienceAffinityClient.candidateToExperience(this.affinityDetail.experience.experienceIdentifier, this.affinityDetail.match.matchIdentifier)
        // .then(x => {
        //     if(x) {
        //         this.$router.push('/new-experience-match/' + x);
        //     }
        //     else {
                
        //     }
        // })
    }

    secondChanceAndNotPremium(){
        this.$opModal.show(noPremiumModal, {
            text: "",
            actionText: "",
            callback: () => {
                this.$opModal.closeAll();

                store.state.afterPremiumAction = experienceAffinityDetailCb(this.experienceIdentifier);

                this.$router.push("/premium");
            }
        })
    }

    affinityNotCompleted(){
        this.$opModal.show(affinityNotCompletedModal, {
            name: this.affinityDetail.match.personName,
            callback: () => {
                this.$opModal.closeLast();
                this.$router.push('/new-experience-match/' + this.affinityDetail.match.matchIdentifier + "/" + this.affinityDetail.experience.experienceIdentifier);
            }
        })
    }

    affinityNotCompletedAndOtherYes(){
        this.$opModal.show(AffinityNotCompletedAndOtherYes, {
            name: this.affinityDetail.match.personName,
            callback: () => {
                this.$opModal.closeLast();
                this.$router.push('/new-experience-match/' + this.affinityDetail.match.matchIdentifier + "/" + this.affinityDetail.experience.experienceIdentifier);
            }
        })
    }

    affinityAlreadyReached(){
        this.$opModal.show(AffinityAlreadyReachedModal, {
            name: this.affinityDetail.match.personName,
            callback: () => {
                ExperienceAffinityClient.candidateToExperience(this.affinityDetail.experience.experienceIdentifier, this.affinityDetail.match.matchIdentifier)
                .then(x => {
                    this.$opModal.closeLast();
                    this.$opModal.show(ExperienceAffinitySuccessModal, {
                        userName: this.affinityDetail.match.personName,
                    })
                });
            }
        })
    }

    affinityAlreadyCompletedAndUnmatchedAndTryAgainUsed(){
        this.$opModal.show(affinityCompletedAndNotReachedModalAndTryAgainUsed, {
            name: this.affinityDetail.match.personName,
            callback: () => {
                ExperienceAffinityClient.candidateToExperience(this.affinityDetail.experience.experienceIdentifier, this.affinityDetail.match.matchIdentifier)
                .then(x => {
                    this.$opModal.closeLast();
                    this.$opModal.show(ExperienceAffinitySuccessModal, {
                        userName: this.affinityDetail.match.personName,
                    })
                });
            }
        })
    }

    affinityAlreadyCompletedAndUnmatched(){
        this.$opModal.show(AffinityCompletedAndNotReachedModal, {
            name: this.affinityDetail.match.personName,
            candidateCallback: () => {
                ExperienceAffinityClient.candidateToExperience(this.affinityDetail.experience.experienceIdentifier, this.affinityDetail.match.matchIdentifier)
                .then(x => {
                    this.$opModal.closeLast();
                    this.$opModal.show(ExperienceAffinitySuccessModal, {
                        userName: this.affinityDetail.match.personName,
                    })
                });
            },
            tryAgainCallback: () => {
                this.canTryAgain();
            }
        })
    }

    canTryAgain() {
        TryAgainClient.canTryAgain(false)
        .then( x => {
            if(!x){
                this.cannotUseTryAgain();
                return;
            }

            this.useTryAgain();
        })
    }

    cannotUseTryAgain(){
        var text = this.$localizationService.getLocalizedValue("app_HaiFinitoIRiprovaDisponibili!", "Hai finito i Riprova disponibili!");

        if(this.tryAgainSummary.isUserPremium) {
            this.$opModal.show(InfoModal, {
                text: text,
                confirm: this.$localizationService.getLocalizedValue("app_HoCapito", "Ho capito"),
                confirmCb: () => {
                    this.$opModal.closeLast();
                }
            })

            return;
        }

        this.$opModal.show(noPremiumModal, {
            text: text,
            actionText: this.$localizationService.getLocalizedValue("app_DiventaPremium", "Diventa premium"),
            callback: () => {
                this.$opModal.closeAll();
                store.state.afterPremiumAction = experienceAffinityDetailCb(this.experienceIdentifier);

                this.$router.push('/premium/');
            }
        })
    }

    useTryAgain(){
        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("app_ConfermiDiVolerUtilizzareIlRiprova?", "Confermi di voler utilizzare il \"Riprova\"?"),
            confirm: this.$localizationService.getLocalizedValue("app_Si", "Si"),
            deny: this.$localizationService.getLocalizedValue("app_No", "No"),
            confirmCb: () => {
                this.$opModal.closeLast();

                TryAgainClient.useTryAgain(this.affinityDetail.match.matchIdentifier)
                .then(x => {
                    this.$opModal.closeAll();
                    this.$router.push('/new-experience-match/' + this.affinityDetail.match.matchIdentifier + "/" + this.affinityDetail.experience.experienceIdentifier);
                })
                .catch(err => {
                    this.$opModal.show(InfoModal, {
                        img: 'face_error.svg',
                        text: err.Message,
                        deny: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                        denyCb: () => {
                            this.$opModal.closeAll();
                        }
                    })
                })
            },
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

   
}
