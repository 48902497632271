
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {
    }
})
export default class noPremiumModal extends Vue {

    @Prop({
        default: ""
    }) text: string;
    localText: string = "";

    @Prop({
        default: ""
    }) actionText: string;
    localActionText: string = "";

    @Prop() callback: any;

    created() {
        this.localText = this.text;
        if(!this.text)
            this.localText = this.$localizationService.getLocalizedValue("app_AbbonatiAPremiumEDaiUnaSecondaChanceAChiHaRispostoAlleTueDomandeMaNonHaRaggiuntoLAffinityRate!", "Abbonati a Premium e dai una Seconda Chance a chi ha risposto alle tue domande ma non ha raggiunto l'Affinity Rate!");

        this.localActionText = this.actionText; 
        if(!this.actionText)
            this.localActionText = this.$localizationService.getLocalizedValue("app_MaggioriInformazioni", "Maggiori informazioni");
    }

}
