import { Vue } from "vue-class-component";
import router from "./router";
import { ExperienceClient, SecondChanceMatchClient } from "./services/Services";
import SecondChanceModal from './views/loggeduser/modals/premium/secondChanceModal.vue';
import store from "./store";

export function newMatchCallback(matchIdentifier: string): () => Promise<void> {
    return () => {
        let prom = new Promise<void>((resolve,reject) => {
            router.push("/finder")
            .then( () => {
                router.push("/new-match/" + matchIdentifier)
                .then( () => {
                    resolve();
                })
            })
        })
    
        return prom;
    }
}

export function secondChanceCallback(secondChanceIdentifier: string): () => Promise<void> {
    return () => {
        let prom = new Promise<void>((resolve,reject) => {
            router.push("/finder")
            .then( () => {
                router.push("/affinity")
                .then( () => {
                    SecondChanceMatchClient.getSecondChance(false)
                    .then( x => {
                        var foundAffinity = x.list.find( x => x.identifier == secondChanceIdentifier);

                        if(!foundAffinity){
                            resolve();
                            return;
                        }

                        if(store.state.openSecondChanceCb)
                            store.state.openSecondChanceCb(secondChanceIdentifier);

                        resolve();
                    })
                })
            })
        })
    
        return prom;
    }
}

export function newExperienceMatchCallback(affinityIdentifier: string, experienceIdentifier: string): () => Promise<void> {
    return () => {
        let prom = new Promise<void>((resolve,reject) => {
            router.push("/finder")
            .then( () => {
                router.push("/experience")
                .then( () => {
                    router.push("/experience-affinity/" + experienceIdentifier)
                    .then( () => {
                        router.push("/new-experience-match/" + affinityIdentifier + "/" + experienceIdentifier)
                        .then( () => {
                            resolve();
                        })
                    })
                })
            })
        })
        
        return prom;
    }
}

export function experienceAffinityDetailCb(experienceIdentifier: string): () => Promise<void> {
    return () => {
        let prom = new Promise<void>((resolve,reject) => {
            router.push("/finder")
            .then( () => {
                router.push("/experience")
                .then( () => {
                    router.push("/experience-affinity/" + experienceIdentifier)
                    .then( () => {
                        resolve();
                    })
                })
            })
        })
        
        return prom;
    }
}

export function experienceSecondChanceCallback(secondChanceIdentifier: string, experienceIdentifier: string): () => Promise<void> {
    return () => {
        let prom = new Promise<void>((resolve,reject) => {
            router.push("/finder")
            .then( () => {
                router.push("/experience")
                .then( () => {
                    router.push("/experience/detail/" + experienceIdentifier)
                    .then( () => {
                        SecondChanceMatchClient.getSecondChance(false)
                        .then( x => {
                            var foundAffinity = x.list.find( x => x.identifier == secondChanceIdentifier);

                            if(!foundAffinity){
                                resolve();
                                return;
                            }

                            if(store.state.experienceOpenSecondChanceCb){
                                ExperienceClient.getMyExperienceById(experienceIdentifier)
                                .then( x => {
                                    var found = x.applicants.find( x => x.affinityIdentifier == secondChanceIdentifier);

                                    if(found)
                                        store.state.experienceOpenSecondChanceCb(found);
                                })
                            }

                            resolve();
                        })
                    })
                })
            })
        })
    
        return prom;
    }
}