
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {
    }
})
export default class AffinityNotCompletedAndOtherYes extends Vue {

    @Prop() name: string;
    @Prop() callback: any;

    created() {}

}
